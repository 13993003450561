import { createAction, createReducer } from '@reduxjs/toolkit';

const patient = createAction('PATIENT');
const updatePatient = createAction('UPDATE_PATIENT');
const updatePatientHistory = createAction('UPDATE_PATIENT_HISTORY');
const updatePatientCarePlan = createAction('UPDATE_PATIENT_CARE_PLAN');
const updatePatientUtils = createAction('UPDATE_PATIENT_UTILS');
const updatePatientDemographics = createAction('UPDATE_PATIENT_DEMOGRAPHICS');
const patientTimer = createAction('PATIENT_TIMER');

const initialState = {};

const Patient = createReducer(initialState, (builder) => {
  builder
    .addCase(patient, (state, action) => ({ ...action.patient, utils: { ...state.utils } }))
    .addCase(updatePatient, (state, action) => ({ ...state, ...action.newData }))
    .addCase(updatePatientHistory, (state, action) => {
      state.patientHistory = action.data;
    })
    .addCase(updatePatientCarePlan, (state, action) => {
      state.carePlan = action.data;
    })
    .addCase(updatePatientUtils, (state, action) => {
      state.utils = action.data;
    })
    .addCase(updatePatientDemographics, (state, action) => {
      state.patientDemographics = action.data;
    })
    .addCase(patientTimer, (state, action) => {
      state.timer = action.data;
    })
    .addDefaultCase(state => state);
});

export default Patient;
