// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';
// Actions
import SetPatient from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// Services
import { getPatientInfo } from '../../../services/patient';
// Views
import PatientSummary from '../../patient/Summary';
import PatientProfile from '../../patient/PatientProfile';
import CarePlan from '../../patient/CarePlan';
import PatientBilling from '../../patient/PatientBilling';
import Auditing from '../../patient/Auditing';
import Medications from '../../patient/Medications';
import PatientHistory from '../../patient/PatientHistory';
import Diagnostics from '../../patient/Diagnostics';
import PatientEnrollment from '../../patient/Enrollment';
import PatientPageMenu from '../../menu/PatientPageMenu';
import Prompts from '../../patient/Prompts';
import Reminders from '../../patient/Reminders';
import CallNotes from '../../patient/CallNotes';
import Finalize from '../../patient/Finalize';
import HealthFactors from '../../patient/HealthFactors';
// Components
import { withRouter } from '../../shared/WithRouter';
import CustomPrompt from '../CustomPrompt';
// Constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';
import { ENABLE_PATIENT_PROMPTS } from '../../../constants/prompts';

export class Patient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPatientSet: false,
      isPanelOpened: true,
    };

    this.promises = {};

    this.loadPatientData = this.loadPatientData.bind(this);
  }

  componentDidMount() {
    const { setPatient } = this.props;

    setPatient();

    this.loadPatientData();
  }

  componentDidUpdate(prevProps) {
    const { params: { id: patientId } } = this.props;
    const { params: { id: prevPatientId } } = prevProps;

    if (patientId !== prevPatientId) {
      const { setPatient } = this.props;

      setPatient();
      this.setState({
        isPatientSet: false,
      }, this.loadPatientData);
    }
  }

  componentWillUnmount() {
    const { setPatient } = this.props;
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });

    // Unload the current patient
    setPatient({});
  }

  handleOpenPanel = () => {
    this.setState(prev => ({ isPanelOpened: !prev.isPanelOpened }));
  }

  loadPatientData() {
    const {
      setPatient, showNotification, patient,
      navigate, params: { tenant: tenantUrl, id: patientId },
    } = this.props;

    const promiseName = 'getPatientInfo';
    const getPatientInfoRequest = getPatientInfo(patientId);
    const getPatientInfoPromise = getPatientInfoRequest.promise;
    this.promises[promiseName] = getPatientInfoRequest;

    getPatientInfoPromise.then((data) => {
      delete this.promises[promiseName];

      setPatient({
        ...patient,
        ...data,
      });

      this.setState({
        isPatientSet: true,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      navigate(`/${tenantUrl}/cn/list`);

      showNotification({
        message: 'Could not load patient data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  render() {
    const { isPanelOpened, isPatientSet } = this.state;
    const { params: { id: patientId }, enablePrompts } = this.props;

    if (!isPatientSet) return null;

    return (
      <div className="ccm-patient-main d-flex mt-2">
        <div className="h-100 ccm-patient-panel-menu">
          <PatientPageMenu
            reloadData={this.loadPatientData}
          />
        </div>
        <div className="h-100 ccm-patient-main-content box-wrapper flex-grow-1 ml-2 py-3 pl-3">
          <Routes>
            <Route path="summary" element={<PatientSummary />} />
            <Route path="patient-profile" element={<PatientProfile />} />
            <Route path="history" element={<PatientHistory />} />
            <Route path="health-factors" element={<HealthFactors />} />
            {enablePrompts && <Route path="prompts" element={<Prompts reloadData={this.loadPatientData} />} />}
            <Route path="medications" element={<Medications />} />
            <Route path="diagnostics" element={<Diagnostics />} />
            <Route path="billing" element={<PatientBilling />} />
            <Route path="care-plan" element={<CarePlan />} />
            <Route path="auditing" element={<Auditing />} />
            <Route path="finalize" element={<Finalize />} />
            <Route path="enrollment" element={<PatientEnrollment />} />
            <Route path="*" element={<Navigate to="summary" />} />
            <Route path="/" element={<Navigate to="summary" />} />
          </Routes>
        </div>
        <div className={`h-100 ccm-patient-panel-right ${isPanelOpened ? 'is-opened' : 'is-closed'} d-flex flex-column box-wrapper ml-2 p-3`}>
          <Button
            size="lg"
            variant="link"
            className="p-0 mb-2"
            onClick={this.handleOpenPanel}
          >
            <i className="bi-list" />
          </Button>

          <Reminders patientId={patientId} isPanelOpened={isPanelOpened} />
          <CallNotes
            patientId={patientId}
            isPanelOpened={isPanelOpened}
          />
          <CustomPrompt />
          {!isPanelOpened && <ReactTooltip id="tooltip-patientPanel" type="info" effect="float" place="left" />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const features = state.tenant && state.tenant.features && state.tenant.features.length > 0
    ? state.tenant.features : [];
  const patientPrompts = features.filter(item => item.featureName === ENABLE_PATIENT_PROMPTS);
  const enablePrompts = patientPrompts && patientPrompts.length === 1 && patientPrompts[0].enabled;
  return {
    enablePrompts,
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPatient: patientData => dispatch(SetPatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Patient));
