// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PromptModal from '../shared/PromptModal';
// Hooks
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
// constants
import messages from '../../constants/messages';

export const CustomPrompt = (props) => {
  const {
    isBlocked, isActivity, isBilling, isEditingCallNotes,
    isExistingActivity, isWorkingOnPrompts, isTimer, patient,
  } = props;

  const [showDialog, setShowDialog] = useState(isBlocked);
  const [
    showPrompt, setIsOnlyTimer, setPatientId, confirmNavigation, cancelNavigation, patientChanged,
  ] = useCallbackPrompt(showDialog);

  const getPromptMessage = () => {
    const promptMessages = [];

    // user tries to exit 'Time Tracking' page
    if (isActivity || isExistingActivity) promptMessages.push(messages.leaveUnSavedTimeTracking);

    // user tries to exit 'Billing' page
    if (isBilling) promptMessages.push(messages.leaveUnSavedBilling);

    // user tries to exit patient page, when is editing a call note
    if (isEditingCallNotes) promptMessages.push(messages.leaveUnSavedData);

    // user tries to exit patient page, when is editing prompts
    if (isWorkingOnPrompts) promptMessages.push(messages.leaveUnSavedPromptsData);

    // user tries to exit with a timer
    if (isTimer) promptMessages.push(messages.leaveUnStoppedTimer.replace('xxx', patient?.timer?.time));

    // Check if we have the timer message plus some activity message and we're changing patients
    let promptMessage = '';
    if (promptMessages.length > 1 && patientChanged) {
      promptMessages.forEach((prompt) => {
        promptMessage = `${promptMessage}\n• ${prompt}`;
      });
      promptMessage = `${messages.leaveUnSavedMultiple}\n${promptMessage}\n\n${messages.leaveConfirmationPrompt}`;
    } else if (promptMessages.length > 0) {
      // Otherwise, only show the first activity message
      promptMessage = `${promptMessages[0]}\n\n${messages.leaveConfirmationPrompt}`;
    }

    return promptMessage;
  };

  const getPromptTitle = () => (patientChanged ? 'Close Patient\'s Chart Confirmation' : 'Alert');

  useEffect(() => {
    if (isBlocked !== showDialog) setShowDialog(isBlocked);
  }, [isBlocked]);

  useEffect(() => {
    // Check if the message only contains the start of the timer message (without the time replaced)
    setIsOnlyTimer(getPromptMessage().startsWith(messages.leaveUnStoppedTimer.split('xxx')[0]));
  }, [
    isActivity, isBilling, isEditingCallNotes, isExistingActivity, isWorkingOnPrompts, isTimer,
  ]);

  useEffect(() => {
    const patientId = patient?.identifiers?.find(identifier => identifier && identifier.IDType === 'anita_pid')?.ID;
    setPatientId(patientId);
  }, [patient]);

  return (
    <PromptModal
      isOpen={showPrompt}
      promptMsg={getPromptMessage()}
      promptTitle={getPromptTitle()}
      cancelAction={cancelNavigation}
      proceedAction={confirmNavigation}
    />
  );
};

function mapStateToProps(state) {
  return {
    isBlocked: state.router.blocked,
    isActivity: state.router.elements && state.router.elements.isActivity,
    isExistingActivity: state.router.elements && state.router.elements.isExistingActivity,
    isBilling: state.router.elements && state.router.elements.isBilling,
    isEditingCallNotes: state.router.elements && state.router.elements.isEditingCallNotes,
    isWorkingOnPrompts: state.router.elements && state.router.elements.isWorkingOnPrompts,
    isTimer: state.router.elements && state.router.elements.isTimer,
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(CustomPrompt);
