import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useBlocker } from './useBlocker';
// actions
import { UnBlockRouteTransitions } from '../actions/router';

export function useCallbackPrompt(when) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showPrompt, setShowPrompt] = useState(false);
  const [isOnlyTimer, setIsOnlyTimer] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [patientChanged, setPatientChanged] = useState(true);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (!showPrompt) setConfirmedNavigation(false);
  }, [showPrompt]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback((nextLocation) => {
    // Determine if we're changing patients
    const patientUrlChange = !nextLocation.location.pathname.includes(patientId);
    setPatientChanged(patientUrlChange);

    // in if condition we are checking next location and current location are equals or not
    let shouldPrompt = !confirmedNavigation
      && nextLocation.location.pathname !== location.pathname;
    if (shouldPrompt) {
      if (isOnlyTimer && patientId) {
        shouldPrompt = patientUrlChange;
        if (!shouldPrompt) {
          // Ensure navigation continues
          setConfirmedNavigation(true);
        }
      }
      setShowPrompt(shouldPrompt);
      setLastLocation(nextLocation);
    }
    return shouldPrompt;
  }, [confirmedNavigation, navigate, isOnlyTimer, patientId]);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
    dispatch(UnBlockRouteTransitions());
  }, []);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  useBlocker(handleBlockedNavigation, when);

  return [
    showPrompt, setIsOnlyTimer, setPatientId, confirmNavigation,
    cancelNavigation, patientChanged,
  ];
}
