import { STATUSES } from './statuses';

export const GENDERS = {
  M: 'Male',
  F: 'Female',
};

export const CARE_PLAN_DEFAULTS = {
  isCarePlanLoaded: false,
  carePlan: {
    carePlanId: null,
    goals: [],
    nextAppointment: {},
    newProblems: [],
    recentCpBy: null,
    recentCpDate: null,
  },
  patient: {
    dateOfBirth: '',
    recentCp: '',
    primaryInsurance: {
      companyName: '',
      planId: '',
    },
    secondaryInsurance: {
      companyName: '',
      planId: '',
    },
    contactInfo: {
      preferredPhone: '',
      homePhone: '',
      workPhone: '',
      mobilePhone: '',
      email: '',
    },
    guardianContact: {
      name: '',
      phoneNumber: '',
      relation: '',
    },
    nextAppointment: {},
    diagnoses: [],
    drugAllergies: [],
    learningMode: {},
    contactMode: {},
    diagnostics: [],
  },
  users: [],
};

export const GOAL_STATUSES = {
  NOT_STARTED: {
    name: 'Not Started',
    classname: 'danger',
  },
  IN_PROGRESS: {
    name: 'In Progress',
    classname: 'warning',
  },
  STRUGGLING: {
    name: 'Struggling',
    classname: 'warning',
  },
  DOING_WELL: {
    name: 'Doing well',
    classname: 'success',
  },
  ON_HOLD: {
    name: 'On Hold',
    classname: 'danger',
  },
  COMPLETED_ON_TIME: {
    name: 'Completed on time',
    classname: 'success',
  },
  FAILED_TO_COMPLETE: {
    name: 'Failed to complete',
    classname: 'danger',
  },
};

export const GOAL_FILTERS = {
  ON_HOLD: { name: 'On Hold' },
  FAILED_TO_COMPLETE: { name: 'Failed' },
  COMPLETED_ON_TIME: { name: 'Completed' },
  ACTIVE: { name: 'Active' },
};

export const MEDICATIONS_SOURCES = {
  ALL: 'ALL',
  EHR: 'EHR',
  COHORT: 'NON-EHR',
};

export const MEDICATIONS_STATUS = {
  ALL: { label: 'All', value: true },
  ACTIVE: { label: 'Active', value: true },
  INACTIVE: { label: 'Inactive', value: false },
};

export const MEDICATION_FIELDS = [
  {
    label: 'Instructions',
    key: 'dosage',
  },
  {
    label: 'Route of administration',
    key: 'route',
  },
  {
    label: 'Start date',
    key: 'startedAt',
    type: 'date',
  },
  {
    label: 'End date',
    key: 'endedAt',
    type: 'date',
  },
];

/**
 * to see all the options visit:
 * https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/config/defaultToolbar.js
 */
export const toolbarConfig = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

export const USER_ROLES = {
  ADMIN: 'TenantAdmin',
  CN: 'TenantUser',
  PES: 'PatientEnrollmentSpecialist',
  CUSTOMER: 'Customer',
};

export const ROLE_NAMES = {
  TenantUser: 'CN',
  TenantAdmin: 'OA',
  Customer: 'Reports Only',
  PatientEnrollmentSpecialist: 'PES',
};

export const ROLE_FULL_NAMES = {
  TenantAdmin: 'Organization Administrator',
  TenantUser: 'Care Navigator',
  Customer: 'Reports Only',
  PatientEnrollmentSpecialist: 'Patient Enrollment Specialist',
};

export const DEMO_ITEM = {
  title: 'Demo Settings',
  url: 'demo-settings',
};

export const OTHER_ITEM = {
  title: 'Other',
  url: 'other',
};

export const PATIENT_ATTRIBUTES_ITEM = {
  title: 'Patient Attributes',
  url: 'patient-attributes',
};

export const ADMIN_TOP_MENU_ITEMS = [
  {
    title: 'Administration',
    url: 'administration',
  },
  {
    title: 'User Activity',
    url: 'user-activity',
  },
  {
    title: 'Settings',
    url: 'settings',
  },
];

export const USER_TOP_MENU_ITEMS = [
  {
    title: 'Account',
    url: 'account',
  },
  {
    title: 'Terms of Service',
    url: 'terms',
  },
];

export const CN_MENU_ITEMS = [
  {
    title: 'Summary',
    url: 'summary',
  }, {
    title: 'Patient Profile',
    url: 'patient-profile',
  }, {
    title: 'History',
    url: 'history',
  }, {
    title: 'Health Factors',
    url: 'health-factors',
  }, {
    title: 'Prompts',
    url: 'prompts',
    iconProperty: 'prompts',
  }, {
    title: 'Medications',
    url: 'medications',
  }, {
    title: 'Lab Results',
    url: 'diagnostics',
    isHiddenForPES: true,
  }, {
    title: 'Billing',
    url: 'billing',
    iconProperty: 'billing',
  }, {
    title: 'Care Plan',
    url: 'care-plan',
    iconProperty: 'carePlan',
    isHiddenForPES: true,
  }, {
    title: 'Time Tracking',
    url: 'auditing',
  }, {
    title: 'Finalize',
    url: 'finalize',
    iconProperty: 'finalize',
    isHiddenForPES: true,
  },
];

export const USER_MENU_ITEMS = [
  {
    title: 'Account',
    url: 'account',
  },
  {
    title: 'Administration',
    url: 'administration',
    roles: [USER_ROLES.ADMIN],
    subsections: [
      {
        title: 'Team',
        id: 'team',
      }, {
        title: 'Workload',
        id: 'workload',
      }, {
        title: 'Care Plan',
        id: 'carePlan',
      },
      {
        title: 'Features',
        id: 'features',
      }, {
        title: 'Eligibility Rules',
        id: 'eligibilityRules',
      },
    ],
  },
  {
    title: 'Patient Outreach',
    url: 'patient-outreach',
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: 'SDoH',
    url: 'sdoh',
    roles: [USER_ROLES.ADMIN],
    subsections: [
      {
        title: 'Health Factors',
        id: 'healthFactors',
      }, {
        title: 'Resources',
        id: 'resources',
      },
    ],
  },
  {
    title: 'Prompts',
    url: 'prompts',
    roles: [USER_ROLES.ADMIN],
  },
  {
    ...PATIENT_ATTRIBUTES_ITEM,
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: 'User Activity',
    url: 'user-activity',
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: 'Settings',
    url: 'settings',
    roles: [USER_ROLES.ADMIN],
    subsections: [
      {
        title: 'Billable Providers',
        id: 'billable-providers',
      },
    ],
  },
  {
    title: 'Terms of Service',
    url: 'terms',
  },
  {
    ...DEMO_ITEM,
    roles: [USER_ROLES.ADMIN],
  },
  {
    ...OTHER_ITEM,
    roles: [USER_ROLES.ADMIN],
  },
];

export const FLIGHT_PLAN_MENU_NAMES = {
  ALERTS: { title: 'Alerts' },
  CALL_SCHEDULE: { title: 'Call Schedule' },
  METRICS: { title: 'Metrics' },
  REMINDERS: { title: 'Reminders' },
  RECENT_WORK: { title: 'Recent Work' },
  STATUS_REVIEW: { title: 'Status Change Review' },
};

export const FLIGHT_PLAN_MENU_ITEMS = [
  {
    title: FLIGHT_PLAN_MENU_NAMES.ALERTS.title,
    url: 'alerts',
    enabled: true,
  }, {
    title: FLIGHT_PLAN_MENU_NAMES.CALL_SCHEDULE.title,
    url: 'call-schedule',
    enabled: true,
    roles: [USER_ROLES.CN, USER_ROLES.PES],
  }, {
    title: FLIGHT_PLAN_MENU_NAMES.METRICS.title,
    url: 'metrics',
    enabled: true,
    roles: [USER_ROLES.ADMIN, USER_ROLES.CN, USER_ROLES.PES],
  }, {
    title: FLIGHT_PLAN_MENU_NAMES.REMINDERS.title,
    url: 'reminders',
    enabled: true,
  }, {
    title: FLIGHT_PLAN_MENU_NAMES.RECENT_WORK.title,
    url: 'recent-work',
    enabled: true,
  },
  {
    title: FLIGHT_PLAN_MENU_NAMES.STATUS_REVIEW.title,
    url: 'status-review',
    enabled: true,
    roles: [USER_ROLES.ADMIN],
  },
];

export const REPORTS_MENU_PROPS = {
  ENROLLMENT: { title: 'Enrollment', url: 'enrollment', enabled: true },
  CHARGES: { title: 'CCM Charges', url: 'ccm-charges', enabled: true },
  ESCALATIONS: { title: 'Patient Escalations', url: 'patient-escalations', enabled: true },
  ELIGIBLE_PROVIDER: { title: 'Eligible Patients by Provider', url: 'patients-provider', enabled: true },
  ENROLLED_PROVIDER: { title: 'Enrolled Patients by Provider', url: 'enrolled-provider', enabled: true },
};

export const REPORTS_MENU_ITEMS = [
  { ...REPORTS_MENU_PROPS.ENROLLMENT },
  { ...REPORTS_MENU_PROPS.CHARGES },
  { ...REPORTS_MENU_PROPS.ESCALATIONS },
  { ...REPORTS_MENU_PROPS.ELIGIBLE_PROVIDER },
  { ...REPORTS_MENU_PROPS.ENROLLED_PROVIDER },
];

export const DIALOG_STYLES = {
  overlay: {
    zIndex: 110,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    borderRadius: 'none',
    padding: '0',
    borderColor: '#dce3ed',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 30px)',
  },
};

export const HINT_TEXT = 'WIll be used for login';

export const INVITE_FORM_STATE = {
  firstName: '',
  lastName: '',
  startDate: '',
  username: '',
  password: '',
  role: null,
  reviewRequired: false,
  mobile: '',
  email: '',
  ringCentralPhone: '',
  zoomPhone: '',
};

export const SIGN_IN_ERROR_CODES = {
  NO_AUTH: 'NO_AUTH',
  CANCELED_TOTP: 'CANCELED_TOTP',
  HAS_TOTP_SETUP: 'HAS_TOTP_SETUP',
  INVALID_TOTP: 'INVALID_TOTP_CODE',
  NEW_PASSWORD: 'NEW_PASSWORD_REQUIRED',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  TOTP_SETUP_REQUIRED: 'TOTP_SETUP_REQUIRED',
  PASSWORD_EXPIRED: 'error_user_password_expired',
};

export const CHANGE_PASSWORD_ERRORS = {
  InvalidParameterException: 'New password does not meet requirements: length must be greater than 8, contain lowercase letters, uppercase letters, numbers and special characters.',
  NotAuthorizedException: 'Old password is incorrect.',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  defaultError: 'Validation error occurred, please check your input.',
};

export const DIALOG_DEFAULTS = {
  overlay: {
    zIndex: 110,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 'none',
    padding: '0',
    borderColor: '#dce3ed',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 30px)',
  },
};

export const LOGOUT_TIMEOUT = 780000; // 13 mins in millis
export const COUNTDOWN_TIMEOUT = 120000; // 2 mins in millis
export const LOCAL_STORAGE_MAIN_KEY = 'CCM_APPLICATION';
export const LOCAL_STORAGE_TIMER_KEY = 'TIMER';

export const ENROLLED_STATUSES = {
  BILLING_ON_HOLD: {
    colour: 'orange',
    name: 'On Hold',
  },
};

export const BILLING_STATUSES = {
  ON_HOLD: {
    name: 'On Hold',
    colour: 'gray',
  },
  SUBMITTED_TO_BILLING: {
    name: 'Submitted to billing',
    colour: 'green',
  },
  ACTIONS_REQUIRED: {
    name: 'Actions required',
    defaultTooltip: 'Please complete actions listed in "Summary"',
    colour: 'red',
  },
  CANNOT_BE_SUBMITTED: {
    name: 'Cannot be submitted',
    defaultTooltip: 'Reason: technical issue',
    tooltips: {
      TCM_ENROLLMENT: 'Reason: patient is enrolled to TCM program',
    },
    colour: 'red',
  },
  BILLING_PENDING: {
    name: 'Billing pending',
    defaultTooltip: 'Waiting for billing submission',
    colour: 'gray',
  },
  PENDING_REVIEW: {
    name: 'Pending review',
    defaultTooltip: 'Pending administrator review',
    colour: 'yellow',
  },
};

export const WARNING_COLOR = 'orange';

export const DAYS_OF_THE_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const PROVIDERS_SORTING_OPTIONS = [
  {
    value: 'FIRST NAME',
    label: 'firstName',
  },
  {
    value: 'LAST NAME',
    label: 'lastName',
  },
];

export const SORTING_OPTIONS = {
  FULL_NAME: 'FULL_NAME',
  CN_FULL_NAME: 'CN_FULL_NAME',
  TOTAL_CCM_MTD: 'TOTAL_CCM_MTD',
  PATIENT_EHR_ID: 'PATIENT_EHR_ID',
  NEXT_ACTION_DATE: 'NEXT_ACTION_DATE',
  LETTER_DATE: 'LETTER_DATE',
  ENROLLMENT_DATE: 'ENROLLMENT_DATE',
  LAST_SATISFACTION_SURVEY: 'LAST_SATISFACTION_SURVEY',
  LAST_QA: 'LAST_QA',
  LAST_QA_STATUS: 'LAST_QA_STATUS',
  LAST_BILLED_DATE: 'LAST_BILLED_DATE',
  CALL_ATTEMPTS: 'CALL_ATTEMPTS',
  POTENTIAL_RAF_UPLIFT: 'POTENTIAL_RAF_UPLIFT',
};

export const SORTING_ORDERS = [
  {
    name: 'Ascending',
    reverse: false,
  },
  {
    name: 'Descending',
    reverse: true,
  },
];

export const ELIGIBILITY_ISSUES = {
  BILLING_ON_HOLD: {
    name: 'This patient will not be billed.',
  },
  DO_NOT_SET_ELIGIBILITY_INFO: {
    name: 'Eligibility info is not set for the patient.',
  },
  CARE_PLAN_NOT_FINALIZED: {
    name: 'Care Plan is not finalized.',
    menuTab: 'finalize',
  },
  BILLING_PROBLEMS_ARE_NOT_CONFIRMED: {
    name: 'Billing information has not been confirmed.',
    menuTab: 'billing',
  },
  PATIENT_IS_NOT_ENROLLED: {
    name: 'Patient is not enrolled.',
  },
  PATIENT_IS_NOT_CONTACTED: {
    name: 'Patient is not contacted.',
  },
  TIME_REACHED_NO_DIRECT_CONTACT: {
    name: 'Direct contact must occur before a bill can be sent for this patient.',
  },
};

export const BILLING_FORM_ERRORS = {
  INVALID_PROBLEMS: 'All problems should be valid.',
  PROBLEMS_COUNT: 'There should be at least two and no more than four problems.',
  EMPTY_PHYSICIAN: 'Physician is required.',
  NOT_UNIQUE_ICD10: 'There should be no problems with the same ICD10 codes.',
  TWO_IDENTICAL_CODES: 'There can be only one problem with the same ICD10 code.',
  BE_BILLABLE_PHYSICIAN: 'Physician must be billable',
};

export const PATIENT_ACTIVE_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DECEASED: 'Deceased',
  INELIGIBLE: 'Ineligible',
};

export const BILLING_FORM_CODE_POSITIONS = {
  FIRST: 'first',
  FIRST_OR_SECOND: 'first or second',
};

export const BILLING_FORM_CODE_ERRORS = {
  invalidCode: {
    code: 'error_invalid_code',
    isDataAllowedToSend: true,
  },
  invalidCodePosition: {
    code: 'error_invalid_code_position',
    isDataAllowedToSend: false,
    color: 'red',
  },
  invalidHistoryCode: {
    code: 'error_invalid_history_code',
    isDataAllowedToSend: true,
  },
  notAllowedHistoryCode: {
    code: 'error_not_allowed_history_code',
    isDataAllowedToSend: false,
    color: 'red',
  },
  shouldBeInHistory: {
    code: 'error_should_be_in_history',
    isDataAllowedToSend: false,
    color: 'red',
  },
  notBillableCode: {
    code: 'error_not_billable_code',
    isDataAllowedToSend: false,
    color: 'red',
  },
};

export const MIME_TYPE = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  pdf: 'application/pdf',
  csv: 'text/csv',
};

export const NOTIFICATION_TYPE = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
};

export const NEXT_ACTION_OPTIONS = [
  { value: 'ALL', label: 'All' },
  { value: 'TODAY', label: 'Today' },
  { value: 'PAST_DUE', label: 'Past Due' },
  { value: 'NEXT_7_DAYS', label: 'Next 7 days' },
  { value: 'NEXT_14_DAYS', label: 'Next 14 days' },
  { value: 'NEXT_30_DAYS', label: 'Next 30 days' },
  { value: 'NO_NOD_SET', label: 'No NOD set' },
];

export const PCP_VISIT_MONTH_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'PAST_MONTH', label: 'Past month' },
  { value: 'PAST_3_MONTH', label: 'Past 3 months' },
  { value: 'PAST_6_MONTHS', label: 'Past 6 months' },
  { value: 'PAST_9_MONTHS', label: 'Past 9 months' },
  { value: 'PAST_YEAR', label: 'Past year' },
  { value: 'OVER_1_YEAR_AGO', label: '1-2 years' },
  { value: 'OVER_2_YEAR_AGO', label: '> 2 years' },
];

export const DATE_RANGE_OPTIONS = [
  { value: 'LESS_THAN_ONE_MONTH', label: 'less than 1 month' },
  { value: 'ONE_TO_THREE_MONTHS', label: '1-3 months' },
  { value: 'THREE_TO_SIX_MONTHS', label: '3-6 months' },
  { value: 'SIX_TO_NINE_MONTHS', label: '6-9 months' },
  { value: 'NINE_TO_TWELVE_MONTHS', label: '9-12 months' },
  { value: 'TWELVE_MONTHS_OR_MORE', label: '12 months or more' },
];

export const DATE_FORMAT = {
  FULL: 'MM/DD/YYYY',
  FULL_WITH_LOWERCASE: 'MM/dd/yyyy',
  YEAR: 'YYYY',
  MONTH: 'MM',
  MONTH_FULL_NAME: 'MMMM',
  FULL_SERVER: 'YYYY-MM-DD',
  FULL_SERVER_SHORT: 'MM-DD-YY',
  FULL_SERVER_WITH_TIME: 'YYYY-MM-DD HH:mm',
  FULL_WITH_DAY: 'dddd, MMMM Do YYYY',
  FULL_WITH_FULL_TIME: 'YYYY-MM-DDThh:mm:ss',
  FULL_WITH_TIME_IN_12_HOURS: 'MM/DD/YY hh:mm a',
  SHORT: 'MM/DD/YY',
  FULL_WITH_TIME: 'MM/DD/YYYY h:mm a',
  DATE_PICKER_SHORT: 'MM/dd/yy',
  MONTH_WITH_DAY: 'MMMM DD',
  MONTH_WITH_YEAR: 'MMMM YYYY',
  FULL_WITH_STING_MONTH: 'MMMM DD, YYYY',
  ONLY_FULL_TIME: 'h:mm:ss a',
  ONLY_TIME: 'HH:mm',
};

export const TIME_FORMAT = {
  TIME_WITH_TIMEZONE: 'hh:mm a z',
  SHORT_TIME: 'h:mm a',
  SHORT_TIME_UPPERCASE: 'h:mm A',
};

export const TIME_SELECTOR = {
  HOUR: 'hour',
  MINUTE: 'minute',
  AM: 'AM',
  PM: 'PM',
};

export const KEY_CODE = {
  ENTER: 13,
};

export const USER_STATUS = {
  ACTIVE: 'Enabled',
};

export const USER_BILLING_STATUSES = ['CC', 'CN'];

export const GOALS_TYPE = {
  BILLED_PATIENTS_PER_MONTH: {
    shortLabel: 'Billed',
    label: 'Patients billing goal',
    role: USER_ROLES.CN,
  },
  ENROLLED_PATIENTS_PER_MONTH: {
    shortLabel: 'Enrolled',
    label: 'Patients enrollment goal',
  },
};

export const ASSIGNED_USERS_STATUS = {
  CN: 'CN',
  OWNER: 'Owner',
};

export const PHONE_MASK = '(999)-999-9999';

export const USER_TYPES = {
  external: 'External',
  internal: 'Internal',
};

export const NOTES_TYPES = {
  ENROLLMENT: { dataType: 'ENROLLMENT', noteType: 'enrollmentNote' },
  CARE_PLAN: { dataType: 'CARE_PLAN', noteType: 'careNavigatorNote' },
};

export const QA_STATUSES = {
  none: {
    type: 'NONE', labelA: 'None', labelB: 'None', color: 'gray',
  },
  accepted: {
    type: 'ACCEPTED', labelA: 'Accepted', labelB: 'Accept', color: 'green',
  },
  requested: {
    type: 'CHANGE_REQUESTED', labelA: 'Changes Requested', labelB: 'Request Changes', color: 'red',
  },
  resubmitted: {
    type: 'RESUBMITTED_REVIEW', labelA: 'Resubmitted for Review', labelB: 'Resubmit for Review', color: 'yellow',
  },
  patient_survey: {
    type: 'PATIENT_SURVEY', labelA: 'Patient Satisfaction Call', labelB: 'Patient Satisfaction Call', color: 'gray',
  },
  acceptFeedback: {
    type: 'ACCEPTED_WITH_FEEDBACK', labelA: 'QA Feedback', labelB: 'Accept, with feedback', color: 'darker-yellow',
  },
  feedbackAcknowledged: {
    type: 'ACKNOWLEDGED', labelA: 'Feedback Acknowledged', labelB: 'Feedback Acknowledged', color: 'darker-yellow',
  },
};

export const QA_STATUS_COLOR_ENABLED = [
  QA_STATUSES.requested.type,
  QA_STATUSES.acceptFeedback.type,
];

export const QA_STATUS_EDIT = [
  QA_STATUSES.accepted.type,
  QA_STATUSES.requested.type,
  QA_STATUSES.acceptFeedback.type,
];

export const OFFICE_ADDRESS = {
  STREET: '945 Concord Street',
  UNIT: 'Unit 714',
  CITY_STATE_ZIP: 'Framingham, MA 01701',
};

export const INACTIVE_REASONS_CODES = {
  administrativeReason: { value: 'Administrative reason such as patient merge', label: 'Administrative reason such as patient merge' },
  pcpNoLonger: { value: 'PCP no longer with practice', label: 'PCP no longer with practice' },
  patientDissatisfied: { value: 'Patient dissatisfied with practice & left', label: 'Patient dissatisfied with practice & left' },
  insuranceNoLonger: { value: 'Patient\'s insurance no longer on panel with practice', label: 'Patient\'s insurance no longer on panel with practice' },
  patientNotBeenSeen: { value: 'Patient has not been seen in 2+ years', label: 'Patient has not been seen in 2+ years' },
  movedOutArea: { value: 'Patient moved out of area', label: 'Patient moved out of area' },
  patientLeftPractice: { value: 'Patient left practice for unknown reason', label: 'Patient left practice for unknown reason' },
  hasPrimaryCare: { value: 'Patient has a primary care provider elsewhere', label: 'Patient has a primary care provider elsewhere' },
};

export const INACTIVE_REASONS = [
  { ...INACTIVE_REASONS_CODES.administrativeReason },
  { ...INACTIVE_REASONS_CODES.pcpNoLonger },
  { ...INACTIVE_REASONS_CODES.patientDissatisfied },
  { ...INACTIVE_REASONS_CODES.insuranceNoLonger },
  { ...INACTIVE_REASONS_CODES.patientNotBeenSeen },
  { ...INACTIVE_REASONS_CODES.movedOutArea },
  { ...INACTIVE_REASONS_CODES.patientLeftPractice },
  { ...INACTIVE_REASONS_CODES.hasPrimaryCare },
];

export const INACTIVE_PES_REASONS = [
  { value: 'Patient dissatisfied with practice', label: 'Patient dissatisfied with practice' },
  { value: 'Patient moved away', label: 'Patient moved away' },
  { value: 'Patient left for unknown reasons', label: 'Patient left for unknown reasons' },
];

export const INELIGIBLE_REASONS_CODES = {
  patientInsuranceChanged: { value: 'Patient’s insurance has changed & new insurance does not cover CCM', label: 'Patient’s insurance has changed & new insurance does not cover CCM' },
  patientDialysis: { value: 'Patient receiving dialysis', label: 'Patient receiving dialysis' },
  patientCcmServices: { value: 'Patient receiving CCM services elsewhere', label: 'Patient receiving CCM services elsewhere' },
  patientHospice: { value: 'Patient in hospice, permanent home health or long term care facility', label: 'Patient in hospice, permanent home health or long term care facility' },
  patientResolved: { value: 'Patient’s chronic conditions have resolved', label: 'Patient’s chronic conditions have resolved' },
  patientReceivingOtherServices: { value: 'Patient is receiving other services that make them ineligible for CCM', label: 'Patient is receiving other services that make them ineligible for CCM' },
  ccmInappropriate: { value: 'CCM deemed inappropriate by provider', label: 'CCM deemed inappropriate by provider' },
  patientNotQualified: { value: 'Not qualified for CCM because patient has less than 2 chronic conditions', label: 'Not qualified for CCM because patient has less than 2 chronic conditions' },
};

export const INELIGIBLE_REASONS = [
  { ...INELIGIBLE_REASONS_CODES.patientInsuranceChanged },
  { ...INELIGIBLE_REASONS_CODES.patientDialysis },
  { ...INELIGIBLE_REASONS_CODES.patientCcmServices },
  { ...INELIGIBLE_REASONS_CODES.patientHospice },
  { ...INELIGIBLE_REASONS_CODES.patientResolved },
  { ...INELIGIBLE_REASONS_CODES.patientReceivingOtherServices },
  { ...INELIGIBLE_REASONS_CODES.ccmInappropriate },
  { ...INELIGIBLE_REASONS_CODES.patientNotQualified },
];

export const INELIGIBLE_PES_REASONS = [
  { ...INELIGIBLE_REASONS_CODES.patientInsuranceChanged },
  { ...INELIGIBLE_REASONS_CODES.patientDialysis },
  { ...INELIGIBLE_REASONS_CODES.patientCcmServices },
  { ...INELIGIBLE_REASONS_CODES.patientHospice },
  { ...INELIGIBLE_REASONS_CODES.patientReceivingOtherServices },
  { ...INELIGIBLE_REASONS_CODES.ccmInappropriate },
  { ...INELIGIBLE_REASONS_CODES.patientNotQualified },
];

export const DISENROLLMENT_REASONS = [
  { title: 'Call times do not meet patient\'s needs' },
  { title: 'Concerns r/t lack of practice support' },
  { title: 'Cost concern' },
  { title: 'Multiple care navigators' },
  { title: 'Passive - unable to reach' },
  { title: 'Patient declined to consent to the CCM/Runways program' },
  { title: 'Patient did not receive timely call back from CN' },
  { title: 'Patient doesn\'t value/understand service' },
  { title: 'Patient non-compliance/unable to resolve behavioral concerns' },
  { title: 'Patient ready for self-management' },
  { title: 'Service doesn\'t meet patient needs' },
  { title: 'Unconfirmed - unable to reach' },
];

export const DECLINE_BY_CARE_PROVIDER_REASONS = [
  'Doesn\'t believe patient\'s acuity requires CCM',
  'Patient in conflicting provider program',
  'Provider doesn\'t support CCM program',
  'Opted out by provider',
];

export const DECLINE_BY_PATIENT_REASONS = [
  'Adequate care from family',
  'Adequate care from other entity',
  'Concerned about cost to Medicare',
  'Cost concerns',
  'Declined during consent review process',
  'Doesn\'t need help',
  'Doesn\'t want help',
  'Has similar services from Health Plan',
  'No clear reason',
  'Provider/Practice related',
  'Skeptical of legitimacy',
];

export const E_STATUS_REASONS = [
  'Insurance eligibility change',
  'Language gap',
  'Patient would like call in future',
  'Unable to reach: bad number',
  'Pending management review',
  'Received max call attempts from dialer',
  'Unable to reach: no answer',
];

export const BULK_CHANGE_STATUSES_WITH_EXTRA_BLOCK = [
  'PS', 'CS', 'I', 'DC', 'DP', 'X', 'E',
];

export const GOAL_CATEGORIES = [
  'Appointments',
  'Blood Pressure',
  'Blood Sugar',
  'Cardiac',
  'Cholesterol',
  'Depression and Anxiety',
  'Diet',
  'Exercise',
  'Hydration',
  'Kidney Health',
  'Medication Adherence',
  'Musculoskeletal',
  'Other',
  'Pain Management',
  'Respiratory',
  'Sleep Habits',
  'Smoking Cessation',
  'Stress Management',
  'Vaccination',
];

export const GOAL_TARGET_COMPLETION = [
  { value: 'ONE_MONTH', label: '1 month', months: 1 },
  { value: 'TWO_MONTHS', label: '2 months', months: 2 },
  { value: 'THREE_MONTHS', label: '3 months', months: 3 },
  { value: 'LONG_TIME', label: 'Long term', months: null },
];

export const NONE_SPECIFIED_STRING = 'None Specified';

export const CALL_TIME_OPTIONS = {
  earlyMorning: { name: 'Early Morning', extraLabel: '(8-10)', value: 'EARLYAM' },
  morning: { name: 'Morning', extraLabel: '(8-12)', value: 'AM' },
  lateMorning: { name: 'Late Morning', extraLabel: '(10-12)', value: 'LATEAM' },
  earlyAfternoon: { name: 'Early Afternoon', extraLabel: '(12-2)', value: 'EARLYPM' },
  afternoon: { name: 'Afternoon', extraLabel: '(12-5)', value: 'PM' },
  lateAfternoon: { name: 'Late Afternoon', extraLabel: '(2-5)', value: 'LATEPM' },
  anyTime: { name: 'Any Time', extraLabel: '(8-5)', value: 'ANYTIME' },
  noneSpecified: { name: NONE_SPECIFIED_STRING, extraLabel: '', value: 'NONE' },
};

export const PATIENT_CALL_TIME_OPTIONS = [
  { ...CALL_TIME_OPTIONS.earlyMorning },
  { ...CALL_TIME_OPTIONS.morning },
  { ...CALL_TIME_OPTIONS.lateMorning },
  { ...CALL_TIME_OPTIONS.earlyAfternoon },
  { ...CALL_TIME_OPTIONS.afternoon },
  { ...CALL_TIME_OPTIONS.lateAfternoon },
  { ...CALL_TIME_OPTIONS.anyTime },
  { ...CALL_TIME_OPTIONS.noneSpecified, default: true },
];

export const ENGOODEN_TEXT_OPTIONS = {
  allTexts: { name: 'All Texts', value: 'ALLTEXTS' },
  noTexts: { name: 'No Texts', value: 'NOTEXTS' },
  noCellPhone: { name: 'No Cell Phone', value: 'NOCELL' },
  none: { name: NONE_SPECIFIED_STRING, value: 'NONE' },
};

export const PATIENT_OPT_IN_ENGOODEN_TEXT_OPTIONS = [
  { ...ENGOODEN_TEXT_OPTIONS.allTexts },
  { ...ENGOODEN_TEXT_OPTIONS.noTexts },
  { ...ENGOODEN_TEXT_OPTIONS.noCellPhone },
  { ...ENGOODEN_TEXT_OPTIONS.none, default: true },
];

export const PATIENT_OPT_IN_TEXT_OPTIONS = [{
  name: 'Yes', value: true,
}, {
  name: 'No', value: false,
}, {
  name: NONE_SPECIFIED_STRING, value: null, default: true,
}];

export const SCHEDULE_SENSITIVE_OPTIONS = {
  yes: { name: 'Yes', value: true },
  no: { name: 'No', value: false },
  none: { name: NONE_SPECIFIED_STRING, value: null },
};

export const PATIENT_SCHEDULE_SENSITIVE_OPTIONS = [
  { ...SCHEDULE_SENSITIVE_OPTIONS.yes },
  { ...SCHEDULE_SENSITIVE_OPTIONS.no },
  { ...SCHEDULE_SENSITIVE_OPTIONS.none, default: true },
];

export const EMPTY_STRING = '---';

export const NOT_SPECIFIED = 'Not Specified';

export const MAX_DIAGNOSIS = 4;

export const MIN_DIAGNOSIS = 2;
export const EMPTY_ADDRESS = 'Street address unknown';

export const DIAGNOSTICS_STATUS = {
  ALL: { label: 'All', value: false },
  RECENT: { label: 'Recent', value: true },
};

export const SEARCH_PARAMS = {
  STATUS:
    { key: 'status', label: 'Status' },
  SORT_BY:
    { key: 'sortBy', label: 'Sort by' },
  SORT_ORDER:
    { key: 'sortOrder', label: 'Sort order' },
  PAGE_NUMBER:
    { key: 'pageNumber', label: 'Page number' },
  FIRST_NAME:
    { key: 'firstName', label: 'First name' },
  LAST_NAME:
    { key: 'lastName', label: 'Last name' },
  EXTERNAL_ID:
    { key: 'externalId', label: 'Patient id' },
  DATE_OF_BIRTH:
    { key: 'dateOfBirth', label: 'Date of birth' },
  PHONE_NUMBER:
    { key: 'phoneNumber', label: 'Phone number' },
  PRIMARY_INSURANCE:
    { key: 'primaryInsurance', label: 'Primary insurance' },
  SECONDARY_INSURANCE:
    { key: 'secondaryInsurance', label: 'Secondary insurance' },
  PHYSICIAN_ID:
    { key: 'physicianId', label: 'PCP name' },
  PHYSICIAN_FIRST_NAME:
    { key: 'physicianFirstName', label: 'PCP first name' },
  PHYSICIAN_LAST_NAME:
    { key: 'physicianLastName', label: 'PCP last name' },
  PRIMARY_PHYSICIAN_LOCATION:
    { key: 'primaryPhysicianLocation', label: 'PCP location' },
  PRIMARY_PHYSICIAN_LOCATION_ID:
    { key: 'primaryPhysicianLocationId', label: 'PCP location' },
  LAST_PCP_VISIT:
    { key: 'lastPcpVisit', label: 'PCP last visit date' },
  CARE_NAVIGATOR_ID:
    { key: 'careNavigatorId', label: 'Assigned CN' },
  BILLING_REPORT_STATUS:
    { key: 'billingReportStatus', label: 'Billing report status' },
  CCM_CONDITIONS:
    { key: 'ccmCondition', label: 'CCM conditions' },
  CHRONIC_CONDITION_IDS:
    { key: 'chronicConditionIds', label: 'CCM conditions' },
  NEXT_ACTION_DATE_RANGE:
    { key: 'nextActionDateRange', label: 'Next action date' },
  SERVICES:
    { key: 'services', label: 'Services' },
  LANGUAGES:
    { key: 'languages', label: 'Languages' },
  HAS_REMINDERS:
    { key: 'hasReminders', label: 'Reminders' },
  CALL_EARLY_MONTH:
    { key: 'callEarlyMonth', label: 'Call early month' },
  PREFERRED_CALL_TIME:
    { key: 'preferredCallTime', label: 'Preferred call time' },
  OUTREACH_TYPE:
    { key: 'outreachType', label: 'Outreach Type' },
  TAG_IDS:
    { key: 'tagIds', label: 'Patient Attributes' },
  CALL_ATTEMPTS:
    { key: 'callAttempts', label: 'Call Attempts' },
  PATIENT_IDS:
    { key: 'patientIds', label: 'Bulk filter applied (Patient Ids)' },
  EHR_IDS:
    { key: 'ehrIds', label: 'Bulk filter applied (EHR Ids)' },
  QUICK_FILTERS:
    { key: 'quickFilters', label: 'Quick Filters' },
  TELEHEALTH_PROVIDERS:
    { key: 'telehealthProviders', label: 'Telehealth Providers' },
};

export const MAX_LENGTH_TRUNCATE_STRING = 52;

export const OUTREACH_TYPE_OPTIONS = [
  { value: 'Postcard', label: 'Postcard' },
  { value: 'Intro Letter', label: 'Intro Letter' },
  { value: 'Welcome Packet', label: 'Welcome Packet' },
  { value: 'Other Query', label: 'Other Query' },
];

export const BATCH_STATUSES = {
  GENERATED: 'GENERATED',
  REQUESTED: 'REQUESTED',
  DOWNLOADED: 'DOWNLOADED',
  INTERNAL_REVIEW: 'INTERNAL_REVIEW',
  CLIENT_REVIEWING: 'CLIENT_REVIEWING',
  CLIENT_APPROVED: 'CLIENT_APPROVED',
  SENT: 'SENT',
  PROOF_APPROVED: 'PROOF_APPROVED',
  MAILED: 'MAILED',
  ENROLLING: 'ENROLLING',
  DISCARD: 'DISCARD',
  COMPLETE: 'COMPLETE',
  CANCELED: 'CANCELED',
};

export const BATCH_STATUS_OPTIONS = [
  { value: BATCH_STATUSES.GENERATED, label: 'Generated', disabled: true },
  { value: BATCH_STATUSES.REQUESTED, label: 'Requested', disabled: true },
  { value: BATCH_STATUSES.DOWNLOADED, label: 'Downloaded' },
  { value: BATCH_STATUSES.INTERNAL_REVIEW, label: 'Internal Review' },
  { value: BATCH_STATUSES.CLIENT_REVIEWING, label: 'Client Reviewing' },
  { value: BATCH_STATUSES.CLIENT_APPROVED, label: 'Client Approved' },
  { value: BATCH_STATUSES.SENT, label: 'Sent' },
  { value: BATCH_STATUSES.PROOF_APPROVED, label: 'Proof Approved' },
  { value: BATCH_STATUSES.MAILED, label: 'Mailed', enabledToCnOrPes: true },
  {
    value: BATCH_STATUSES.ENROLLING,
    label: 'Enrolling',
    subtext: '* Moves all patients in this batch from N status to P status.',
    enabledToCnOrPes: true,
  },
  { value: BATCH_STATUSES.DISCARD, label: 'Discard' },
  {
    value: BATCH_STATUSES.COMPLETE,
    label: 'Complete',
    disabled: false,
    enabledToCnOrPes: true,
  },
  { value: BATCH_STATUSES.CANCELED, label: 'Canceled' },
];

export const BATCH_METHODS = {
  QUERY: 'Query',
  TAGGED_PATIENTS: 'Tagged Patients',
};

export const TIME_OPTIONS = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
  { value: 'NONE', label: 'Not Specified' },
];

const AUDITING_CODES = {
  AttemptedEnrollmentCall: { value: 'Attempted Enrollment Call', label: 'Attempted Enrollment Call' },
  enrolled: { value: 'Enrolled', label: 'Enrolled' },
  chartReviewDocumentation: { value: 'Chart Review/Documentation', label: 'Chart Review/Documentation' },
  mocAttempt: { value: 'MOC Attempt', label: 'MOC Attempt' },
  monthlyOutreachCall: { value: 'Monthly Outreach Call', label: 'Monthly Outreach Call' },
  careCoordination: { value: 'Care Coordination', label: 'Care Coordination' },
  disenrollment: { value: 'Disenrollment', label: 'Disenrollment' },
};

export const AUDITING_TITLE_OPTIONS = [
  { ...AUDITING_CODES.AttemptedEnrollmentCall },
  { ...AUDITING_CODES.enrolled },
  { ...AUDITING_CODES.chartReviewDocumentation },
  { ...AUDITING_CODES.mocAttempt },
  { ...AUDITING_CODES.monthlyOutreachCall },
  { ...AUDITING_CODES.careCoordination },
  { ...AUDITING_CODES.disenrollment },
];

export const CALL_ATTEMPTS_OPTIONS = [
  AUDITING_CODES.AttemptedEnrollmentCall.value,
  AUDITING_CODES.monthlyOutreachCall.value,
];

export const NEW_DATA_DATE_RANGE = 60;

export const BLOOD_SUGAR_TYPE_OPTIONS = [
  { value: 'FASTING', label: 'Fasting' },
  { value: 'RANDOM', label: 'Random' },
];

export const USERS_SORT_STATUSES = {
  enabled: 'EnabledSorted',
  disabled: 'DisabledSorted',
  all: 'AllSorted',
};

export const UNASSIGNED_ID = 'not_assigned';

export const PATIENT_CONTROL_TYPE = 'userAssignment';

export const PES_PATIENT_CONTROL_TYPE = 'userAssignmentPES';

export const DEFAULT_PROFILE = {
  homePhoneInvalid: false,
  cellPhoneInvalid: false,
  workPhoneInvalid: false,
  emergencyContactHipaaRelease: false,
  textMessageConsent: null,
  consentToTextMessages: null,
  monthCallEarly: null,
  preferredCallTime: null,
  preferredName: null,
};

export const DIGITAL_PHONE_LENGTH = 10;

export const MFA_TYPES = {
  SMS_MFA: 'SMS_MFA',
  APP_MFA: 'SOFTWARE_TOKEN_MFA',
};

export const VACCINATION_TYPES = {
  VACCINATION: [
    'Covid-19 1st Vaccine',
    'Covid-19 2nd Vaccine',
    'Covid-19 Booster Vaccine',
    'Influenza (Flu) Vaccine',
    'Pneumococcal (Pneumonia) Vaccine',
    'Shingles Vaccine',
    'Tdap/Tetanus',
  ],
};

export const NEXT_OUTREACH_DATE_BLANK = {
  contactMethod: null,
  date: null,
  id: null,
  preferredCallTime: null,
};

export const DEFAULT_FIELDS_LENGTH = {
  DEFAULT_LENGTH: 45,
  DEFAULT_SECOND_LENGTH: 128,
  DEFAULT_THIRD_LENGTH: 255,
  DEFAULT_FOURTH_LENGTH: 512,
  DEFAULT_FIFTH_LENGTH: 1024,
  DEFAULT_MAX_LENGTH: 10240,
};

export const MEDICAL_EVENTS_SOURCES = {
  PATIENT: 'Patient',
  EHR: 'EMR',
  HIE: 'HIE',
  CLAIMS: 'CLAIMS',
  COHORT: 'Patient',
};

export const PASSWORD_REGEX_CHARACTERS = '(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\\´:;|_~`])';

export const PASSWORD_REGEX_NUMBERS = '(?=.*[0-9])';

export const PASSWORD_REGEX_UPPERCASE = '(?=.*[A-Z])';

export const PASSWORD_REGEX_LOWERCASE = '(?=.*[a-z])';

const SUSPEND_BILLING_CATEGORIES_CODES = {
  homeHealth: { value: 'HOME_HEALTH', label: 'Home Health', active: false },
  hospital: { value: 'HOSPITAL', label: 'Hospital', active: false },
  inpatientRehab: { value: 'IRF', label: 'Inpatient Rehab Facility', active: false },
  betweenProviders: { value: 'BETWEEN_PROVIDERS', label: 'Between providers', active: true },
  accountableCare: { value: 'ACO', label: 'Accountable Care Organization / Direct Contracting Entity', active: false },
  nursingFacility: { value: 'SNF', label: 'Skilled Nursing Facility', active: false },
  pendingDisenrollment: { value: 'PENDING_DISENROLLMENT', label: 'Pending Disenrollment', active: false },
  beginningDialysis: { value: 'BEGINNING_DIALYSIS', label: 'Beginning Dialysis', active: false },
  hospice: { value: 'TRANSITIONING_TO_HOSPICE', label: 'Transitioning to Hospice', active: false },
  other: { value: 'OTHER', label: 'Other', active: true },
  hospitalIrfSnf: { value: 'HOSPITAL_IRF_SNF', label: 'Hospital / IRF / SNF', active: true },
  biMonthlyCallCadence: { value: 'BIMONTHLY_CALL_CADENCE', label: 'Bi-monthly call cadence', active: true },
  quarterlyCallCadence: { value: 'QUARTERLY_CALL_CADENCE', label: 'Quarterly call cadence', active: true },
  costConcern: { value: 'COST_CONCERN', label: 'Cost concern', active: true },
  pendingManagerReview: { value: 'PENDING_MANAGER_REVIEW', label: 'Pending manager review', active: true },
};

export const SUSPEND_BILLING_CATEGORIES = [
  { ...SUSPEND_BILLING_CATEGORIES_CODES.homeHealth },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.hospital },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.inpatientRehab },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.betweenProviders },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.accountableCare },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.nursingFacility },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.pendingDisenrollment },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.beginningDialysis },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.hospice },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.other },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.hospitalIrfSnf },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.biMonthlyCallCadence },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.quarterlyCallCadence },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.costConcern },
  { ...SUSPEND_BILLING_CATEGORIES_CODES.pendingManagerReview },
];

export const SATISFACTION_SURVEYS_RESPONSES = {
  firstOptions: [
    { value: 'Very Satisfied', label: 'Very Satisfied' },
    { value: 'Satisfied', label: 'Satisfied' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Dissatisfied', label: 'Dissatisfied' },
    { value: 'Very Dissatisfied', label: 'Very Dissatisfied' },
  ],
  secondOptions: [
    { value: 'Strongly Agree', label: 'Strongly Agree' },
    { value: 'Agree', label: 'Agree' },
    { value: 'Neutral', label: 'Neutral' },
    { value: 'Disagree', label: 'Disagree' },
    { value: 'Strongly Disagree', label: 'Strongly Disagree' },
  ],
};

export const SATISFACTION_SURVEYS_QUESTIONS = {
  questionOne: {
    row: 'Experience with CCM:',
    modal: 'How would you rate your experience with the Chronic Care Management Program?',
  },
  questionTwo: {
    row: 'Care Navigator Responsiveness:',
    modal: 'Is your Care Navigator responsive to your needs?',
  },
  questionThree: {
    row: 'Overall Care Navigator Care:',
    modal: 'How would you rate the overall care you receive from your Care Navigator',
  },
  questionFour: {
    row: 'CCM Impact:',
    modal: 'Do you feel the Chronic Care Management Program has made a positive impact on your health care needs?',
  },
  questionFive: { row: 'Comment:' },
};

export const ALERT_FILTER_CONSTANTS = {
  INSURANCE_CHANGE: { name: 'INSURANCE_CHANGE' },
  PATIENT_INELIGIBLE: { name: 'PATIENT_INELIGIBLE' },
  PATIENT_BIRTHDAY: { name: 'PATIENT_BIRTHDAY' },
  PATIENT_DECEASED: { name: 'PATIENT_DECEASED' },
  PCP_CHANGED: { name: 'PCP_CHANGED' },
  MISSING_TT_ENTRY: { name: 'MISSING_TT_ENTRY' },
  PATIENT_UNSUBSCRIBE_TEXT: { name: 'PATIENT_UNSUBSCRIBE_TEXT' },
  UPCOMING_AWV: { name: 'UPCOMING_AWV' },
  UNHAPPY_PATIENT: { name: 'UNHAPPY_PATIENT' },
  PT_BACK_IN_OFFICE: { name: 'PT_BACK_IN_OFFICE' },
  PHONE_NUMBER_UPDATED: { name: 'PHONE_NUMBER_UPDATED' },
  UPCOMING_AWV_30_DAYS: { name: 'UPCOMING_AWV_30_DAYS' },
  MULTI_PATIENT_HOUSEHOLD: { name: 'MULTI_PATIENT_HOUSEHOLD' },
};

export const FLIGHT_PLAN_ALERT_FILTER = [
  { value: '', label: 'All Alerts' },
  { value: ALERT_FILTER_CONSTANTS.INSURANCE_CHANGE.name, label: 'Insurance Changed' },
  { value: ALERT_FILTER_CONSTANTS.PATIENT_INELIGIBLE.name, label: 'Patient status changed to Ineligible' },
  { value: ALERT_FILTER_CONSTANTS.PATIENT_BIRTHDAY.name, label: 'Patient has an upcoming birthday' },
  { value: ALERT_FILTER_CONSTANTS.PATIENT_DECEASED.name, label: 'Patient has died' },
  { value: ALERT_FILTER_CONSTANTS.PCP_CHANGED.name, label: 'Patient PCP changed' },
  { value: ALERT_FILTER_CONSTANTS.MISSING_TT_ENTRY.name, label: 'Care Plan updated without corresponding time tracking entry' },
  { value: ALERT_FILTER_CONSTANTS.PATIENT_UNSUBSCRIBE_TEXT.name, label: 'Patient has unsubscribed from texting' },
  { value: ALERT_FILTER_CONSTANTS.UPCOMING_AWV.name, label: 'Patient has an upcoming AWV' },
  { value: ALERT_FILTER_CONSTANTS.UPCOMING_AWV_30_DAYS.name, label: 'Patient has an AWV within the next 30 days' },
  { value: ALERT_FILTER_CONSTANTS.UNHAPPY_PATIENT.name, label: 'Patient dissatisfied with CCM services' },
  { value: ALERT_FILTER_CONSTANTS.PT_BACK_IN_OFFICE.name, label: 'Patient has recently returned to PCP' },
  { value: ALERT_FILTER_CONSTANTS.PHONE_NUMBER_UPDATED.name, label: 'Previously unreachable patient has updated phone number' },
  { value: ALERT_FILTER_CONSTANTS.MULTI_PATIENT_HOUSEHOLD.name, label: 'There may be more than one CCM patient at this household' },
];

export const PES_SUMMARY_ALERTS = {
  [ALERT_FILTER_CONSTANTS.PATIENT_BIRTHDAY.name]: { color: 'warning' },
  [ALERT_FILTER_CONSTANTS.MULTI_PATIENT_HOUSEHOLD.name]: { color: 'warning' },
};

const RAW_STATUSES = {
  N: { value: 'N', label: `${STATUSES.N.name} (N)` },
  P: { value: 'P', label: `${STATUSES.P.subStatuses.P.name} (P)` },
  P2: { value: 'P2', label: `${STATUSES.P.subStatuses.P2.name} (P2)` },
  P3: { value: 'P3', label: `${STATUSES.P.subStatuses.P3.name} (P3)` },
  PN: { value: 'PN', label: `${STATUSES.P.subStatuses.PN.name} (PN)` },
  PS: { value: 'PS', label: `${STATUSES.P.subStatuses.PS.name} (PS)` },
  CN: { value: 'CN', label: `${STATUSES.C.subStatuses.CN.name} (CN)` },
  CC: { value: 'CC', label: `${STATUSES.C.subStatuses.CC.name} (CC)` },
  CS: { value: 'CS', label: `${STATUSES.C.subStatuses.CS.name} (CS)` },
  I: { value: 'I', label: `${STATUSES.I.name} (I)` },
  DP: { value: 'DP', label: `${STATUSES.D.subStatuses.DP.name} (DP)` },
  DC: { value: 'DC', label: `${STATUSES.D.subStatuses.DC.name} (DC)` },
  X: { value: 'X', label: `${STATUSES.X.name} (X)` },
  E: { value: 'E', label: `${STATUSES.E.name} (E)` },
};

const ALL_STATUSES_FOR_FILTERS = [
  { value: '', label: 'All' },
  { ...RAW_STATUSES.N },
  { ...RAW_STATUSES.P },
  { ...RAW_STATUSES.P2 },
  { ...RAW_STATUSES.P3 },
  { ...RAW_STATUSES.PN },
  { ...RAW_STATUSES.PS },
  { ...RAW_STATUSES.CN },
  { ...RAW_STATUSES.CC },
  { ...RAW_STATUSES.CS },
  { ...RAW_STATUSES.I },
  { ...RAW_STATUSES.DP },
  { ...RAW_STATUSES.DC },
  { ...RAW_STATUSES.X },
  { ...RAW_STATUSES.E },
];

const NEW_STATUSES_FOR_FILTERS = [
  { value: '', label: 'All' },
  { ...RAW_STATUSES.PS },
  { ...RAW_STATUSES.CS },
  { ...RAW_STATUSES.DP },
  { ...RAW_STATUSES.X },
  { ...RAW_STATUSES.I },
];

const OLD_STATUSES_FOR_FILTERS = [
  { value: '', label: 'All' },
  { ...RAW_STATUSES.N },
  { ...RAW_STATUSES.P },
  { ...RAW_STATUSES.P2 },
  { ...RAW_STATUSES.P3 },
  { ...RAW_STATUSES.CN },
  { ...RAW_STATUSES.CC },
];

export const REVIEW_NAMES = {
  NOT_REVIEWED: { name: 'NOT_REVIEWED' },
  REVIEWED_ACCEPT: { name: 'REVIEWED_ACCEPT' },
  REVIEWED_DENIED: { name: 'REVIEWED_DENIED' },
};

export const FLIGHT_PLAN_FILTERS = {
  filters: [
    { value: 'status', label: 'Status' },
    { value: 'engoodenId', label: 'SelectPatient Management ID' },
    { value: 'ehrId', label: 'EHR ID' },
  ],
  statuses: ALL_STATUSES_FOR_FILTERS,
};

export const FLIGHT_PLAN_STATUSES_FILTERS = {
  newStatus: NEW_STATUSES_FOR_FILTERS,
  oldStatus: OLD_STATUSES_FOR_FILTERS,
  reviewStatus: [
    { value: REVIEW_NAMES.NOT_REVIEWED.name, label: 'Needs Review' },
    { value: REVIEW_NAMES.REVIEWED_ACCEPT.name, label: 'Reviewed & Accepted' },
    { value: REVIEW_NAMES.REVIEWED_DENIED.name, label: 'Reviewed & Denied' },
  ],
  dateRange: [
    { value: 'TODAY', label: 'Today' },
    { value: 'LAST_7_DAYS', label: 'Last 7 days' },
    { value: 'LAST_30_DAYS', label: 'Last 30 days' },
    { value: 'LAST_90_DAYS', label: 'Last 90 days' },
  ],
};

export const AUTH_PROVIDERS = {
  KEYCLOAK: 'Keycloak',
  COGNITO: 'Cognito',
};

export const FINALIZATION_CHECKLIST = [
  { id: 1, displayName: 'All time tracking entries are complete' },
  { id: 2, displayName: 'All applicable prompts are addressed' },
  { id: 3, displayName: 'Goals are reviewed and updated with progress added where needed' },
  { id: 4, displayName: 'Appropriate interventions have been added' },
  { id: 5, displayName: 'All documentation is accurate and complete' },
];

export const FINALIZE_ALERTS = {
  missingTtEntry: {
    id: 0, variant: 'danger', message: 'No time tracking has been entered for this patient for the current month. Please complete time tracking before finalizing.', active: true,
  },
  missingDirectContact: {
    id: 1, variant: 'danger', message: 'Time tracking has been entered, but there has been no direct contact. You must speak to your patient or their care giver at least once before finalizing for the month.', active: true,
  },
  missingCnPhoneConfig: {
    id: 3, variant: 'warning', message: 'Your account is missing a Ring Central or Zoom phone number. Please work with your SCN to get this set up.', active: true,
  },
  missingPtTextPreference: {
    id: 4, variant: 'warning', message: 'No text messaging preferences have been set for this patient. Please remember to ask about texting preferences during your next MOC.', active: true,
  },
  dueForSatisfactionSurvey: {
    id: 12, variant: 'warning', message: '', active: false,
  },
  unresolvedInsights: {
    id: 13, variant: 'warning', message: 'This patient has unresolved insights. Please review insights before finalizing. If appropriate, discuss insights during next MOC.', active: false,
  },
  missingPrompts: {
    id: 14, variant: 'danger', message: 'Please review and complete the Prompts section for this patient before finalizing', active: true,
  },
};

export const ENROLLMENT_CODES = {
  enrollPatient: { id: 'enrollPatient', label: 'Enroll this patient' },
  scheduleCall: { id: 'scheduleCall', label: 'Schedule a follow-up call' },
  declineFutureCall: { id: 'declineFutureCall', label: 'Open to future call (document decline)' },
  declinePermanent: { id: 'declinePermanent', label: 'Permanent (document decline)' },
  patientHistory: { id: 'patientHistory', label: 'View patient history' },
  otherActions: { id: 'otherActions', label: 'Complete other actions' },
};

export const PATIENT_ENROLLMENT_OPTIONS = [
  { ...ENROLLMENT_CODES.enrollPatient },
  { ...ENROLLMENT_CODES.scheduleCall },
  { ...ENROLLMENT_CODES.declineFutureCall },
  { ...ENROLLMENT_CODES.declinePermanent },
  { ...ENROLLMENT_CODES.otherActions },
];

export const MONTHLY_CALL_OPTIONS = [
  { value: '', label: 'Any' },
  { value: 'ZERO_CALLS', label: '0' },
  { value: 'ONE_CALL', label: '1' },
  { value: 'TWO_CALLS', label: '2' },
  { value: 'THREE_OR_MORE_CALLS', label: '3+' },
];

export const BULK_FILTER_IDS_REGEX = /^\w+(,\s*\w+)*$/;
export const BULK_FILTER_NUMBERS_IDS_REGEX = /^\d+(,\s*\d+)*$/;

export const TENANT_FEATURES_NAMES = {
  SHOW_RAF_SCORES: { name: 'SHOW_RAF_SCORES' },
  ENABLE_PRE_CALL_TEXTS: { name: 'ENABLE_PRE_CALL_TEXTS' },
  ENABLE_TEXT_INTEGRATION: { name: 'ENABLE_TEXT_INTEGRATION' },
  ALLOW_TEMPORARY_TAKEOVER: { name: 'ALLOW_TEMPORARY_TAKEOVER' },
  ENABLE_MANUAL_EMR_UPDATE: { name: 'ENABLE_MANUAL_EMR_UPDATE' },
  LIFESTYLE_GOALS_ONLY: { name: 'LIFESTYLE_GOALS_ONLY' },
  ONE_CARE_PLAN_PER_MONTH: { name: 'ONE_CARE_PLAN_PER_MONTH' },
  ENABLE_PCP_PHONE: { name: 'ENABLE_PCP_PHONE' },
  ENABLE_MULTI_TELEHEALTH: { name: 'ENABLE_MULTI_TELEHEALTH' },
  ENABLE_FUTURE_INSURANCE: { name: 'ENABLE_FUTURE_INSURANCE' },
};

export const PATIENT_STATUS = {
  firstView: ['N', 'P', 'P2', 'P3'],
  secondView: ['CN', 'CC', 'CS'],
  differentFlow: ['DC', 'DP', 'PN', 'PS', 'X', 'I', 'E'],
};

export const RESOURCES_FILTERS = {
  ALL: { name: 'All' },
  INACTIVE: { name: 'Inactive' },
  ACTIVE: { name: 'Active' },
};
