import { createAction, createReducer } from '@reduxjs/toolkit';

const blockRouteTransitions = createAction('BLOCK_ROUTE_TRANSITIONS');
const unblockRouteTransitions = createAction('UNBLOCK_ROUTE_TRANSITIONS');

const initialState = {
  blocked: false,
  elements: {},
};

const setIsBlocked = (elements) => {
  let blocked = false;
  Object.keys(elements).forEach((elem) => {
    if ((typeof elements[elem]) === 'boolean') {
      blocked = elements[elem] || blocked;
    }
  });

  return blocked;
};

const setValue = (state, action, value) => {
  if (action.elementName) {
    const elements = {
      ...state.elements,
      [action.elementName]: action.value || value,
    };
    state.elements = elements;
    state.blocked = setIsBlocked(elements);
  } else {
    // If this was called with no elementName, then set blocked globally.
    state.blocked = value;
  }
};


const Router = createReducer(initialState, (builder) => {
  builder
    .addCase(blockRouteTransitions, (state, action) => {
      setValue(state, action, true);
    })
    .addCase(unblockRouteTransitions, (state, action) => {
      setValue(state, action, false);
    })
    .addDefaultCase(state => state);
});

export default Router;
