export default {
  'error.email.not_valid': 'Email is not valid',
  leaveUnSavedTimeTracking: 'The new activity has not been saved and will be lost.',
  leaveUnSavedBilling: 'Billing updates have not been saved and will be lost.',
  leaveUnStoppedTimer: 'You have spent xxx in the patient\'s chart that has not been logged.',
  leaveUnSavedData: 'Documentation has not been saved and will be lost.',
  leaveUnSavedPromptsData: 'Prompts have not been saved and will be lost.',
  leaveUnSavedMultiple: 'There are unsaved activities that will be lost if you close the chart now. Your pending activities are:',
  leaveConfirmationPrompt: 'Are you sure you want to leave without saving this activity? Select OK to close the chart or Cancel to continue your work.',
};
