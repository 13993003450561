// Libraries
import React, { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Custom Hooks
import useLoadAlerts from '../../../hooks/services/useLoadAlerts';
import useLoadPatientInfo from '../../../hooks/services/useLoadPatientInfo';
import useLoadPatientSummary from '../../../hooks/services/useLoadPatientSummary';
// Views
import MainContent from '../../simplePesView/MainContent';
import BasicActions from '../../simplePesView/BasicActions';
import ReferenceInformation from '../../simplePesView/ReferenceInformation';
// Constants
import { PES_SUMMARY_ALERTS } from '../../../constants/constants';
// Actions
import SetPatient from '../../../actions/patient';

export const SimplePesView = () => {
  const alertsFP = [];
  const componentRef = useRef(null);
  const { id: patientId } = useParams();
  const { status } = useSelector(state => state.patient);
  const dispatch = useDispatch();

  const { refetch } = useLoadPatientInfo({ patientId, loadBillingInfo: true });
  useLoadPatientSummary({ patientId });
  const { alertsFlightPlan } = useLoadAlerts({ patientId });

  if (alertsFlightPlan.patientAlertsInfo && alertsFlightPlan.patientAlertsInfo.length > 0) {
    const allTypeAlerts = alertsFlightPlan.patientAlertsInfo[0].alerts
      .filter(element => Object.keys(PES_SUMMARY_ALERTS)
        .includes(element.alertInfo.type));

    if (allTypeAlerts.length > 0) {
      allTypeAlerts.forEach((element, index) => {
        alertsFP.push({
          id: 1 + index,
          variant: PES_SUMMARY_ALERTS[element.alertInfo.type].color,
          message: element.description,
        });
      });
    }
  }

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [status]);

  useEffect(() => () => {
    dispatch(SetPatient({}));
  }, []);

  return (
    <section className="ccm-patient-view-main d-flex h-100 mt-2">
      <section className="ccm-patient-view-main-content overflow-auto container-fluid box-wrapper pr-1" ref={componentRef}>
        <div className="row no-gutters h-100">
          <div className="col-10 p-4">
            <div className="row no-gutters">
              <div className="col-8 d-flex flex-column text-left">
                <h4 className="text-capitalize mb-4">patient enrollment</h4>
                {alertsFP.sort((a, b) => a.id - b.id).map(item => (
                  <Alert key={`alert_${item.id}`} variant={item.variant} className="mb-1 py-2 px-3">
                    <i className={`bi ${item.icon ? item.icon : 'bi-exclamation-triangle'} mr-2`} />
                    <span className="ml-2" data-test="finalize_alerts">{item.message}</span>
                  </Alert>))}
                <MainContent addOptions />
              </div>
              <div className="col-4 px-4">
                <BasicActions />
              </div>
            </div>
          </div>
          <div className="col-2 text-left py-4 pl-4 pr-1 section-reference-information separator">
            <ReferenceInformation reloadPatientInfo={refetch} />
          </div>
        </div>
      </section>
    </section>
  );
};

export default SimplePesView;
